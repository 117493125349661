// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Dash_teste__78tl3{\n    display: flex;\n    justify-content: center;\n    margin: auto;\n}\n.Dash_porc__Tq58p {\n \n    font-size: 50px;\n    color: #153240;\n    font-weight: 600;\n    position: absolute;\n    margin-top: 300px;\n    margin-left: 310px;\n}\n.Dash_info__Q4DjB{\n    font-size: 20px;\n    margin-top: -55px;\n    margin-left: 280px;\n}\n\n.Dash_logoImage__yvfHI {\n    width: 780px;\n    height: 245px;\n  }\n  \n  .Dash_centerLogo__pCjOa {\n    display: flex;\n    justify-content: center;\n    width: 100%; \n    padding-top: 5%;\n    height: 100vh;\n  }  ", "",{"version":3,"sources":["webpack://./src/pages/Dashboard/Dash.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,YAAY;AAChB;AACA;;IAEI,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,aAAa;EACf;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,WAAW;IACX,eAAe;IACf,aAAa;EACf","sourcesContent":[".teste{\n    display: flex;\n    justify-content: center;\n    margin: auto;\n}\n.porc {\n \n    font-size: 50px;\n    color: #153240;\n    font-weight: 600;\n    position: absolute;\n    margin-top: 300px;\n    margin-left: 310px;\n}\n.info{\n    font-size: 20px;\n    margin-top: -55px;\n    margin-left: 280px;\n}\n\n.logoImage {\n    width: 780px;\n    height: 245px;\n  }\n  \n  .centerLogo {\n    display: flex;\n    justify-content: center;\n    width: 100%; \n    padding-top: 5%;\n    height: 100vh;\n  }  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"teste": "Dash_teste__78tl3",
	"porc": "Dash_porc__Tq58p",
	"info": "Dash_info__Q4DjB",
	"logoImage": "Dash_logoImage__yvfHI",
	"centerLogo": "Dash_centerLogo__pCjOa"
};
export default ___CSS_LOADER_EXPORT___;

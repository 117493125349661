import React, { useState, useEffect } from "react";
import axios from "axios";
import style from "./Header.module.css";
import LogoAxM from '../../img/Logo-AxM.png';
import useWebSocket, { ReadyState } from 'react-use-websocket';

function Header() {
  const [user, setUser] = useState('');
  const [setor, setSetor] = useState('');
  const [status, setStatus] = useState(true);

  // Usando o hook useWebSocket para criar a conexão
  const { lastMessage, readyState } = useWebSocket('/ws/', {
    shouldReconnect: (closeEvent) => true, // Habilita a reconexão automática
    reconnectAttempts: 10, // Número máximo de tentativas de reconexão
    reconnectInterval: 3000, // Intervalo entre as tentativas (3 segundos)
  });

  useEffect(() => {
    if (lastMessage !== null) {
      const message = lastMessage.data;
      console.log('Mensagem WebSocket recebida:', message);

      if (message === 'A') {
        setStatus(true); // Conectado
      } else if (message === 'I') {
        setStatus(false); // Desconectado
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    const fetchEmpresa = async () => {
      try {
        const response = await axios.get('/api/login/token/empresa', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        setUser(response.data['user']); 
        setSetor(response.data['setor']); 
      } catch (error) {
        console.error('Erro ao buscar a empresa:', error);
      }
    };
    fetchEmpresa();
  }, []);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Conectando',
    [ReadyState.OPEN]: 'Conectado',
    [ReadyState.CLOSING]: 'Fechando',
    [ReadyState.CLOSED]: 'Desconectado',
    [ReadyState.UNINSTANTIATED]: 'Sem conexão',
  }[readyState];

  return (
    <div className={style.header}>
      <div className={style.conteudo}>
        <img src={LogoAxM} alt="Logo" className={style.logo} />
        <div className={style.rightContainer}>
          <div className={status ? style.statusContainer : style.statusContainerF}>
            <div className={status ? style.statusCircle : style.statusCircleF}></div>
            <div className={status ? style.statusText : style.statusTextF}>
              {status ? 'Conectado' : 'Desconectado'}
            </div>
          </div>
          <div className={style.nome}>
            {user} - {setor}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SubmitButton_form_control__eOjpP button {\n    width: auto;\n    background: #2D99AA;\n    font-size: 19px;\n    color: white;\n    font-family: 'sans-serif';\n    border: none;\n    border-radius: 9px;\n    cursor: pointer;\n    transition: background 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;\n    padding: 8px 10px;\n    margin: 5px 2px 5px auto;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n}\n\n.SubmitButton_form_control__eOjpP button:hover {\n    background: #206D79;\n    box-shadow: 0 0 0 3px #ffffffd8, 0 0 0 4px #206D79, 0 8px 16px rgba(0, 0, 0, 0.2);\n    transform: translateY(-2px);\n}\n", "",{"version":3,"sources":["webpack://./src/components/Button/SubmitButton.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,mBAAmB;IACnB,eAAe;IACf,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,2EAA2E;IAC3E,iBAAiB;IACjB,wBAAwB;IACxB,wCAAwC;AAC5C;;AAEA;IACI,mBAAmB;IACnB,iFAAiF;IACjF,2BAA2B;AAC/B","sourcesContent":[".form_control button {\n    width: auto;\n    background: #2D99AA;\n    font-size: 19px;\n    color: white;\n    font-family: 'sans-serif';\n    border: none;\n    border-radius: 9px;\n    cursor: pointer;\n    transition: background 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;\n    padding: 8px 10px;\n    margin: 5px 2px 5px auto;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n}\n\n.form_control button:hover {\n    background: #206D79;\n    box-shadow: 0 0 0 3px #ffffffd8, 0 0 0 4px #206D79, 0 8px 16px rgba(0, 0, 0, 0.2);\n    transform: translateY(-2px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form_control": "SubmitButton_form_control__eOjpP"
};
export default ___CSS_LOADER_EXPORT___;

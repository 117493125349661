import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styles from './ImgUpload.module.css';

const ImageUpload = ({ onUpload }) => {
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      setImage(file);
      
      // Create a preview of the image
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
      console.log(file, '----------------------------------------------------------------------------')

      onUpload(file);  // Call the upload function, pass formData
    }
  }, [onUpload]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({ onDrop });

  return (
    <div>
      <div
        className={`${styles.ImageUploadDropZone} ${isDragActive ? styles.dragging : ''}`}
        {...getRootProps()}
      >
        <input className={styles.imgUploadContainer} {...getInputProps()} />
        {image ? (
          <>
            <p>{image.name}</p>
            {preview && <img src={preview} alt="Preview" className={styles.previewImage} />}
          </>
        ) : (
          <p>{isDragActive ? "Solte sua imagem aqui!" : "Arraste ou Selecione o Arquivo da Nomeação"}</p>
        )}
      </div>
    </div>
  );
};

export default ImageUpload;

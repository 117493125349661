import React, { useEffect, useState } from 'react';
import { buscarEstados, buscarCidades } from '../../services/api-ibge.js';

const LocationInput = ({ selectState, selectCity, onStateChange, onCityChange }) => {
    const [states, setStates] = useState([
        { id: 'AC', sigla: 'AC' },
        { id: 'AL', sigla: 'AL' },
        { id: 'AM', sigla: 'AM' },
        { id: 'AP', sigla: 'AP' },
        { id: 'BA', sigla: 'BA' },
        { id: 'CE', sigla: 'CE' },
        { id: 'DF', sigla: 'DF' },
        { id: 'ES', sigla: 'ES' },
        { id: 'GO', sigla: 'GO' },
        { id: 'MA', sigla: 'MA' },
        { id: 'MG', sigla: 'MG' },
        { id: 'MS', sigla: 'MS' },
        { id: 'MT', sigla: 'MT' },
        { id: 'NF', sigla: 'NF' },
        { id: 'PA', sigla: 'PA' },
        { id: 'PB', sigla: 'PB' },
        { id: 'PE', sigla: 'PE' },
        { id: 'PI', sigla: 'PI' },
        { id: 'PR', sigla: 'PR' },
        { id: 'RJ', sigla: 'RJ' },
        { id: 'RN', sigla: 'RN' },
        { id: 'RO', sigla: 'RO' },
        { id: 'RR', sigla: 'RR' },
        { id: 'RS', sigla: 'RS' },
        { id: 'SC', sigla: 'SC' },
        { id: 'SE', sigla: 'SE' },
        { id: 'SP', sigla: 'SP' },
        { id: 'TO', sigla: 'TO' }
    ]);
    const [cities, setCities] = useState([]);
    const [selectedState, setSelectedState] = useState(selectState);
    const [selectedCity, setSelectedCity] = useState(selectCity);

    useEffect(() => {
        setSelectedState(selectState);
        setSelectedCity(selectCity);
        setCities([]); // Clear cities when state changes
    }, [selectState, selectCity]);

    useEffect(() => {
        const loadCities = async () => {
            if (selectedState) {
                const citiesData = await buscarCidades(selectedState);
                setCities(citiesData);
            }
        };

        loadCities();
    }, [selectedState]);

    const handleStateChange = async (event) => {
        const stateId = event.target.value;
        setSelectedState(stateId);
        setSelectedCity(""); // Clear selected city when state changes
        setCities([]);
        if (stateId) {
            const citiesData = await buscarCidades(stateId);
            setCities(citiesData);
        }
        onStateChange(stateId);
    };

    const handleCityChange = (event) => {
        const cityId = event.target.value;
        setSelectedCity(cityId);
        onCityChange(cityId);
    };

    return (
        <div className='col-span-2 eventual-local'>
            <select id="state" name="state" onChange={handleStateChange} value={selectedState} required>
                <option value="">UF</option>
                {states.map((state) => (
                    <option key={state.id} value={state.id}>{state.sigla}</option>
                ))}
            </select>
            <select id="city" name="city" onChange={handleCityChange} value={selectedCity} required>
                <option value="">Selecione uma cidade</option>
                {cities.map((city) => (
                    <option key={city.id} value={city.id}>{city.nome}</option>
                ))}
            </select>
        </div>
    );
};

export default LocationInput;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import Container from '../../components/Container/index.js';

import './user.css';
import person from "../../img/person.svg";
import mail from "../../img/mail.svg";
import people from "../../img/people.svg";
import locked from "../../img/locked.svg";
import document from "../../img/document.svg";
import phone from "../../img/phone.svg";
import Logo_Codesp from "../../img/Logo_Codesp.png";

const UserSettings = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [formStatus, setFormStatus] = useState(true);
    const [codespStatus, setCodespStatus] = useState(true);
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        nome: '',
        mail: '',
        setor: '',
        cpf: '',
        usuario: '',
        telefone: '',
        senha: '',
    });
    const [codespData, setCodespData] = useState({
        cnpj_codesp: '',
        cpf_codesp: '',
        senha_codesp: '',
    });

    const handleForm = (event) => {
        event.preventDefault();
        setFormStatus(!formStatus);

        if (!formStatus) {
            // Salvar dados quando formStatus for false (edição concluída)
            saveUserData();
        }
    };

    const handleCodespForm = async (event) => {
        event.preventDefault();
        
        if (!codespStatus) {
            const codespEdit = await saveCodespData();
            if (codespEdit.status === 200) {
                setCodespStatus(!codespStatus);
            }
        } else {
            setCodespStatus(!codespStatus);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleCodespInputChange = (event) => {
        const { name, value } = event.target;
        setCodespData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const fetchUserData = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.log('Token não encontrado no localStorage');
                return;
            }

            const response = await axios.get('/api/usuario/dados', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const dados = response.data[0];
            setFormData({
                nome: dados.NOME_USUARIO,
                mail: dados.EMAIL_USUARIO,
                setor: dados.SETOR_USUARIO,
                cpf: dados.CPF_USUARIO,
                usuario: dados.USUARIO,
                telefone: dados.TELEFONE,
            });
            setCodespData({
                cnpj_codesp: dados.CNPJ_CODESP,
                cpf_codesp: dados.CPF_CODESP,
                senha_codesp: dados.SENHA_CODESP,
            })

        } catch (error) {
            console.error('Erro ao buscar dados do usuário:', error);
        }
    };

    const saveUserData = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('token');
            await axios.put('/api/usuario/dados', formData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            enqueueSnackbar('Dados do usuário atualizados com sucesso!', { variant: 'success' });
        } catch (error) {
            console.error('Erro ao atualizar dados do usuário:', error);
            enqueueSnackbar('Erro ao atualizar dados     do usuário', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const saveCodespData = async () => {
        setLoading(true);
        let insertResponse = null;
        
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post('/api/login/teste', codespData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
    
            if (response.status === 200) {
                insertResponse = await axios.put('/api/usuario/codesp/dados', codespData, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                enqueueSnackbar('Dados da Codesp atualizados com sucesso!', { variant: 'success' });
            } else {
                throw new Error('Falha ao autenticar');
            }
        } catch (error) {
            console.error('Erro ao atualizar dados da Codesp:', error);
            enqueueSnackbar('Credenciais da Codesp incorretas', { variant: 'error' });
        } finally {
            setLoading(false);
            return insertResponse;
        }
    };
    

    useEffect(() => {
        if (localStorage.getItem('token')) {
            fetchUserData();
        }
    }, []);

    return (
        <>
            <Container>
                <div className='user-container'>
                    <form onSubmit={handleForm}>
                        <div className='user-grid-2'>
                            <div className='nav'>
                                <h1 className='navItem'>
                                    <i className="fa fa-gear"></i> Configurações de usuário
                                </h1>
                            </div>
                            <div className='user-salvar'>
                                <button className='user-salvar-button btn btn-white btn-animate' type='submit'>
                                    {formStatus ? 'Editar' : 'Salvar'}
                                </button>
                            </div>
                        </div>
                        <div className='formulario'>
                            <div className='mt-2'>
                                <label htmlFor='nome'>Nome completo</label>
                                <div className="input-with-icon">
                                    <img src={person} className='user-icon' alt='Nome completo' />
                                    <input
                                        onChange={handleInputChange}
                                        className="col-md-2"
                                        type="text"
                                        name='nome'
                                        value={formData.nome}
                                        disabled={formStatus}
                                    />
                                </div>
                            </div>
                            <div className='mt-2'>
                                <label htmlFor='mail'>Endereço de E-Mail</label>
                                <div className="input-with-icon">
                                    <img src={mail} className='user-icon-mail' alt='E-mail' />
                                    <input
                                        onChange={handleInputChange}
                                        className="col-md-2"
                                        type="text"
                                        name='mail'
                                        value={formData.mail}
                                        disabled={formStatus}
                                    />
                                </div>
                            </div>
                            <div className='mt-2'>
                                <label htmlFor='setor'>Setor</label>
                                <div className="input-with-icon">
                                    <img src={people} className='user-icon' alt='Setor' />
                                    <input
                                        onChange={handleInputChange}
                                        className="col-md-2"
                                        type="text"
                                        name='setor'
                                        value={formData.setor}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className='mt-2 user-grid-2'>
                                <div>
                                    <label htmlFor='cpf'>CPF</label>
                                    <div className="input-with-icon">
                                        <img src={document} className='user-icon' alt='CPF' />
                                        <input
                                            onChange={handleInputChange}
                                            className="col-md-2"
                                            type="text"
                                            name='cpf'
                                            value={formData.cpf}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor='telefone'>Número de telefone</label>
                                    <div className="input-with-icon">
                                        <img src={phone} className='user-icon' alt='Telefone' />
                                        <input
                                            onChange={handleInputChange}
                                            className="col-md-2"
                                            type="text"
                                            name='telefone'
                                            value={formData.telefone}
                                            disabled={formStatus}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='mt-2 user-grid-2'>
                                <div>
                                    <label htmlFor='usuario'>Usuário</label>
                                    <div className="input-with-icon">
                                        <img src={person} className='user-icon' alt='Usuário' />
                                        <input
                                            onChange={handleInputChange}
                                            className="col-md-2"
                                            type="text"
                                            name='usuario'
                                            value={formData.usuario}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor='senha'>Senha</label>
                                    <div className="input-with-icon">
                                        <img src={locked} className='user-icon' alt='Senha' />
                                        <input
                                            onChange={handleInputChange}
                                            className="col-md-2"
                                            type="text"
                                            name='senha'
                                            placeholder='*********'
                                            value={formData.senha}
                                            disabled={formStatus}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <hr className='separator' />
                    <form onSubmit={handleCodespForm}>
                        <div className='formulario'>
                            <div className='user-grid-2'>
                                <h1 className='user-config-title'>
                                    <img src={Logo_Codesp} alt="config-icon" className='user-config-icon' />
                                    Codesp
                                </h1>
                                <div className='user-salvar'>
                                    <button className='user-salvar-button btn btn-white btn-animate' type='submit' disabled={loading}>
                                        {codespStatus ? 'Editar' : 'Salvar'}
                                    </button>
                                </div>
                            </div>
                            <div className='mt-2'>
                                <label htmlFor='cnpj_codesp'>CNPJ</label>
                                <div className="input-with-icon">
                                    <img src={document} className='user-icon' alt='CNPJ' />
                                    <input
                                        onChange={handleCodespInputChange}
                                        className="col-md-2"
                                        type="text"
                                        name='cnpj_codesp'
                                        value={codespData.cnpj_codesp}
                                        disabled={codespStatus}
                                    />                 
                               </div>
                            </div>
                            <div className='mt-2'>
                                <label htmlFor='cpf_codesp'>CPF</label>
                                <div className="input-with-icon">
                                    <img src={person} className='user-icon-mail' alt='Usuário' />
                                    <input
                                        onChange={handleCodespInputChange}
                                        className="col-md-2"
                                        type="text"
                                        name='cpf_codesp'
                                        value={codespData.cpf_codesp}
                                        disabled={codespStatus}
                                    />
                                </div>
                            </div>
                            <div className='mt-2 mb-5'>
                                <label htmlFor='senha_codesp'>Senha</label>
                                <div className="input-with-icon">
                                    <img src={locked} className='user-icon' alt='Senha' />
                                    <input
                                        onChange={handleCodespInputChange}
                                        className="col-md-2"
                                        type="text"
                                        name='senha_codesp'
                                        value={codespData.senha_codesp}
                                        disabled={codespStatus}
                                    />                                
                               </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Container>
        </>
    );
}

export default UserSettings;

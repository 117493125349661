import React, { useState, useEffect } from "react";
import Container from "../../../components/Container";
import style from "./HistoricoMotivacao.module.css";
import SubmitButton from '../../../components/Button';
import Axios from "axios";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { SnackbarProvider, useSnackbar } from "notistack";
import Modal from 'react-modal';
import modalStyle from './Modal.module.css';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const HistoricoMotivacao = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [motivacoes, setMotivacoes] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  const showAlert = (message, variant) => {
    enqueueSnackbar(message, { variant: variant });
  };

  useEffect(() => {
    Axios.get("/api/motivacao", {
      headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
      }
  })
      .then((response) => {
        setMotivacoes(response.data);
        console.log(response.data)
      })
      .catch((error) => {
        console.error("Erro ao buscar as motivações: ", error);
        enqueueSnackbar("Erro ao buscar as motivações", {variant: "error"});
      });
  }, [enqueueSnackbar]);

  const copyToClipboardFallback = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
      showAlert("Texto copiado com sucesso!", "success");
    } catch (err) {
      showAlert("Erro ao copiar o texto.", "error");
      console.error("Falha ao copiar: ", err);
    }

    document.body.removeChild(textArea);
  };

  const handleCopy = async (event, idMotivacao) => {
    event.preventDefault();
    try {
      const response = await Axios.get(`/api/motivacao/conteudo/${idMotivacao}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      const textToCopy = response.data.content;
      console.log(textToCopy)
      if (navigator.clipboard) {
        navigator.clipboard.writeText(textToCopy).then(() => {
          showAlert("Texto copiado com sucesso!", "success");
        }, (err) => {
          console.error("Erro ao copiar usando a API da área de transferência: ", err);
          copyToClipboardFallback(textToCopy);
        });
      } else {
        copyToClipboardFallback(textToCopy);
      }
    } catch (error) {
      console.error('Erro ao tentar copiar o texto: ', error);
      showAlert("Erro ao copiar o texto.", "error");
    }
  };

  const handleShowContent = (motivados) => {
    setModalContent(motivados);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalContent("");
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = motivacoes.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(motivacoes.length / itemsPerPage);

  return (
    <>
      <Container>
        <div className={style.content}>
          <div className={style.nav}>
            <div className={style.navItem} onClick={() => navigate('/motivacao/motivados')}>
              <i className="fa fa-home"></i> Motivados
            </div>
            <div className={`${style.active} ${style.navItem}`}>
              <i className="fa fa-history"></i> Histórico
            </div>
            <div className={style.navItem} onClick={() => navigate('/motivacao/cadastro')}>
              <i className="fa fa-user-plus"></i> Cadastro
            </div>
          </div>
          <table className={style.table}>
            <thead>
              <tr>
                <th>ID</th>
                <th>BERÇO</th>
                <th>PERÍODO</th>
                <th>USUÁRIO</th>
                <th>EMPRESA</th>
                <th>CADASTRO</th>
                <th>CPFs</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((motivacao) => (
                <tr key={motivacao.ID_MOTIVACAO}>
                  <td>{motivacao.ID_MOTIVACAO}</td>
                  <td><div className={style.truncatedText}>
                  {motivacao.DESC_BERCO_SPA && motivacao.DESC_BERCO_SPA.split(', ').map((line, index) => (
      <div key={index}>{line}</div>
    ))}</div></td>
                  <td>
                    {moment(motivacao.DATA_INICIO).format('DD/MM/YY')} - {motivacao.DATA_TERMINO ? moment(motivacao.DATA_TERMINO).format('DD/MM/YY') : 'N/A'}
                  </td>
                  <td>{motivacao.USER_CADASTRO_MOTIVACAO}</td>
                  <td>{motivacao.NOME_REDUZIDO}</td>
                  <td>{moment(motivacao.DATA_CADASTRO_MOTIVACAO).format('DD/MM/YY HH:mm')}</td>
                  <td>
                    <span className={style.cpfList} onClick={() => handleShowContent(motivacao.MOTIVADOS)}>
                      <i className="fa fa-user-plus"></i>
                    </span>
                  </td>
                  <td>
                    <div  className={
                      motivacao.STATUS === 'Em espera' ? style.status_espera :
                      motivacao.STATUS === 'Recusado' ? style.status_recusado :
                      motivacao.STATUS === 'Aprovado' ? style.status_aprovado :
                      style.status_default
                    }>
                      {motivacao.STATUS}
                    </div>
                  </td>
                  <td>
                    <div className={style.buttonContainer}>
                      <SubmitButton text="Copiar" onClick={(event) => handleCopy(event, motivacao.ID_MOTIVACAO)} className={style.copyButton}/>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Stack spacing={2} className={style.pagination}>
            <Typography>Page: {currentPage}</Typography>
            <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} />
          </Stack>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Motivação Conteúdo"
            className={modalStyle.modal}
            overlayClassName={modalStyle.overlay}
          >
            <div className={modalStyle.nav}>
              <div className={modalStyle.active}>
                <i className="fa fa-exclamation-circle"></i> CPFs Motivados!
              </div>
            </div>
            <div className={modalStyle.columns}>
              {modalContent.split(';').map((item, index) => (
                <div key={index} className={modalStyle.columnItem}>{item}</div>
                ))
                }
            </div>
            <SubmitButton text="Fechar" onClick={closeModal} />
          </Modal>
        </div>
      </Container>
    </>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      maxSnack={3}
      autoHideDuration={2500}
    >
      <HistoricoMotivacao />
    </SnackbarProvider>
  );
}

import React from 'react';
import { FaArrowLeft } from 'react-icons/fa'; // Corrigido para usar a importação correta
import './modal.css'; // Para o estilo do modal

const Modal = ({ isOpen, onClose, onBack, children }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <button className="close-btn" onClick={onClose}>
                    &times;
                </button>
                <button className="back-btn" onClick={onBack}>
                    <FaArrowLeft /> {/* Use o componente diretamente */}
                </button>
                {children}
            </div>
        </div>
    );
};

export default Modal;

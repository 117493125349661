import React, { useState, useEffect } from "react";
import Container from "../../../components/Container";
import style from "./CadastroMotivado.module.css";
import SubmitButton from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import InputMask from 'react-input-mask';
import { listarEmpresas } from '../../../services/api-empresas.js';

import { SnackbarProvider, useSnackbar } from 'notistack';

const CadastroMotivado = () => {

    const [nome_motivado, setNome] = useState("");
    const [email_motivado, setEmail] = useState("");
    const [empresa, setEmpresa] = useState("");
    const [status, setStatus] = useState("");
    const [cpf_motivado, setCpf] = useState("");
    const [empresas, setEmpresas] = useState([]);
    const [telefone, setTelefone] = useState("")
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const fetchEmpresas = async () => {
            try {
                const data = await listarEmpresas();
                setEmpresas(data);
            } catch (error) {
                console.error('Erro ao buscar empresas:', error);
            }
        };

        fetchEmpresas();
    }, []);

    const postMotivado = () => {
        const cpfFormatted = cpf_motivado.replace(/[^\d]/g, '');
        const telefoneFormatted = telefone.replace(/[^\d]/g, '');

        //TODO Verificar função no back
        Axios.post("/api/motivacao/criar", {
            nome_motivado: nome_motivado,
            email_motivado: email_motivado,
            cpf_motivado: cpfFormatted,
            status: status,
            empresa: empresa,
            telefone: telefoneFormatted,
            usuario_cadastro: 'estela'
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(function (res) {
                console.log(res);
                console.log("res");
                if (res.data.sqlMessage) {
                    showAlert(res.data.sqlMessage, 'error');
                } else {
                    showAlert('Motivado cadastrado com sucesso!', 'success');
                    setTimeout(() => {
                        navigate("/motivacao/motivados"); // Redirecionar após o atraso
                    }, 3000); // Atraso de 3000 milissegundos (3 segundos)
                }
            });
    }

    const showAlert = (txt, variant) => {
        enqueueSnackbar(txt, { variant: variant });
    }

    const isValidCPF = (cpf) => {
        cpf = cpf.replace(/[^\d]+/g, ''); // Remove non-numeric characters
        if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;

        let sum = 0;
        for (let i = 0; i < 9; i++) sum += parseInt(cpf.charAt(i)) * (10 - i);
        let remainder = (sum * 10) % 11;

        if (remainder === 10 || remainder === 11) remainder = 0;
        if (remainder !== parseInt(cpf.charAt(9))) return false;

        sum = 0;
        for (let i = 0; i < 10; i++) sum += parseInt(cpf.charAt(i)) * (11 - i);
        remainder = (sum * 10) % 11;

        if (remainder === 10 || remainder === 11) remainder = 0;
        if (remainder !== parseInt(cpf.charAt(10))) return false;

        return true;
    };

    const verificaCPFExistente = async (cpf) => {
        try {
            const response = await Axios.get(`/api/motivacao/busca/${cpf}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data.length > 0;
        } catch (error) {
            console.error("Erro na verificação do CPF:", error);
            showAlert('Erro ao verificar CPF', 'error');
            return false;
        }
    };

    
    const validaTecla = (e) => {
        if (e.key === 'Enter') {
            validaDados()
        }
    }

    const validaDados = async () => {
        if (!cpf_motivado || !nome_motivado || !empresa || !status || !telefone) {
            return showAlert('Preencha todos os campos', 'error');
        }

        if (!isValidCPF(cpf_motivado)) {
            return showAlert('CPF inválido', 'error');
        }

        const cpfFormatado = cpf_motivado.replace(/[^\d]+/g, '');
        const cpfExiste = await verificaCPFExistente(cpfFormatado);
        if (cpfExiste) {
            return showAlert('CPF já cadastrado', 'error');
        }

        postMotivado();
    };

    const navigate = useNavigate();

    return (
        <>
            <Container>
                <div className={style.content}>
                    <div className={style.nav}>
                        <div className={style.navItem} onClick={() => navigate('/motivacao/motivados')}>
                            <i className="fa fa-home"></i> Motivados
                        </div>
                        <div className={style.navItem} onClick={() => navigate('/motivacao/historicomotivacao')}>
                            <i className="fa fa-history"></i> Histórico
                        </div>
                        <div className={`${style.active} ${style.navItem}`}>
                            <i className="fa fa-user-plus"></i> Cadastro
                        </div>
                    </div>
                    <div className={style.columns}>

                        <div className={style.nome}>
                            <label>Nome do Motivado</label>
                            <input
                                type="text"
                                id="nomeMotivado"
                                onChange={(e) => setNome(e.target.value.toUpperCase())}
                                onKeyPress={(e) => validaTecla(e)}
                            />
                        </div>

                        <div className={style.nome}>
                            <label>E-mail</label>
                            <input
                                type="text"
                                id="emailMotivado"
                                onChange={(e) => setEmail(e.target.value)}
                                onKeyPress={(e) => validaTecla(e)}
                            />
                        </div>

                        <div className={style.cpf}>
                            <label>CPF</label>
                            <InputMask
                                type="cpf"
                                mask="999.999.999-99"
                                placeholder="000.000.000-00"
                                onChange={(e) => setCpf(e.target.value)}
                                onKeyPress={(e) => validaTecla(e)}
                            />
                        </div>

                        <div className={style.cpf}>
                            <label>Telefone</label>
                            <InputMask
                                type="cpf"
                                mask="(99) 99999-9999"
                                placeholder="(99) 99999-9999"
                                onChange={(e) => setTelefone(e.target.value)}
                                onKeyPress={(e) => validaTecla(e)}
                            />
                        </div>

                        <div className={style.empresa}>
                            <label htmlFor="empresaMotivado">Empresa</label>
                            <select
                                id="empresaMotivado"
                                onChange={(e) => setEmpresa(e.target.value)}
                                onKeyPress={(e) => validaTecla(e)}
                            >
                                <option value="">Selecione a Empresa</option>
                                {empresas.map((empresa, index) => (
                                    <option key={index} value={empresa.COD_EMPRESA}>{empresa.NOME_REDUZIDO}</option>
                                ))}
                            </select>
                        </div>

                        <div className={style.status}>
                            <label>Status</label>
                            <select
                                id="empresaMotivado"
                                onChange={(e) => setStatus(e.target.value)}
                                onKeyPress={(e) => validaTecla(e)}
                            >
                                <option value="">Selecione o Status</option>
                                <option value="A">ATIVO</option>
                                <option value="I">INATIVO</option>
                            </select>
                        </div>

                    </div>

                    <div className={style.submitButton}>
                        <SubmitButton text="Cadastrar" onClick={validaDados} onKeyPress={(e) => validaTecla(e)} />
                    </div>
                </div>
            </Container>
        </>
    );
};
export default function IntegrationNotistack() {
    return (
        <SnackbarProvider
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            maxSnack={3}
            autoHideDuration={2500}>
            <CadastroMotivado />
        </SnackbarProvider >
    );
}

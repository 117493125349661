import React, { useState, useEffect } from "react";
import style from './aprovacao.module.css';
import Container from '../../components/Container/index.js';
import CalliBot from '../../img/CaliBot_Mot_Selec.png'
import { useSnackbar } from 'notistack';
import Modal from "../../components/Modal/modal.jsx";


import { FaCheck, FaBan, FaCalendar } from 'react-icons/fa';
import axios from 'axios';

const Aprovacao = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [empresa, setEmpresa] = useState('');
  const [motivo, setMotivo] = useState('');
  const [idMotivacao, setIdMotivacao] = useState('');
  const [periodo, setPeriodo] = useState('');
  const [bercos, setBercos] = useState('');
  const [motivados, setMotivados] = useState([]);
  const [motivacoes, setMotivacoes] = useState([]);
  const [nomeacao, setNomeacao] = useState('')
  const [nomeacaoButton, setNomeacaoButton] = useState(false)
  const [selectedMotivacaoId, setSelectedMotivacaoId] = useState(null);
  const [modal, setModal] = useState(false)
  const [disabledButtons, setDisabledButtons] = useState({});


  useEffect(() => {
    axios.get("/api/motivacao/listar/aguardando", {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
      .then(response => {
        const data = response.data;
        console.log('Dados retornados pela API:', data);

        if (Array.isArray(data)) {
          setMotivacoes(data);  
        } else {
          console.warn('Estrutura de dados inesperada:', data);
          setMotivacoes([]);
        }
      })
      .catch(error => {
        console.error('Erro ao buscar os dados:', error);
      });
  }, []);

  const handleMotivacaoAction = async (motivacaoId, actionType) => {
    try {
      setDisabledButtons(prev => ({ ...prev, [motivacaoId]: true }))
      const token = localStorage.getItem('token');
  
      const response = await axios.post(
         '/api/motivacao/aprovacao',
        {
          id_motivacao: motivacaoId,
          type: actionType,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      console.log('Resposta da API:', response.data);
  
      setMotivacoes((prevMotivacoes) =>
        prevMotivacoes.filter((motivacao) => motivacao.ID_MOTIVACAO !== motivacaoId)
      );
  
      setEmpresa('');
      setBercos('');
      setMotivo('');
      setIdMotivacao('');
      setPeriodo('');
      setMotivados([]);

      enqueueSnackbar(`Cadastro ${actionType} com sucesso!`, { variant: 'success' });
      
    } catch (error) {
      enqueueSnackbar(`Falha no processo!`, { variant: 'error' });
      console.error('Erro ao enviar a requisição:', error);
    } finally {
      setDisabledButtons(prev => ({ ...prev, [idMotivacao]: false }))
    }
  };
  

  const handleMotivacaoClick = (motivacao) => {
    setEmpresa(motivacao.NOME_REDUZIDO);
    setBercos(motivacao.DESC_BERCO_SPA.replace(/,/g, '\n'));
    setMotivo(motivacao.JUSTIFICATIVA);
    setNomeacao("/api/motivacao/nomeacao/"+motivacao.ID_MOTIVACAO)
    setNomeacaoButton(motivacao.NOMEACAO)
    setIdMotivacao(motivacao.ID_MOTIVACAO);
    setPeriodo(`${new Date(motivacao.DATA_INICIO).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' })} - ${new Date(motivacao.DATA_TERMINO).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' })}`);

    const motivadosArray = motivacao.MOTIVADOS.split(';').map((item) => {
      const [nome, cpf] = item.split(' - ');
      return { nome: nome.trim(), cpf: cpf ? cpf.trim() : '' };
    });

    setMotivados(motivadosArray);
    setSelectedMotivacaoId(motivacao.ID_MOTIVACAO);
  };

  const handleModal = () => {
    setModal(!modal)
  }

  return (
    <>
      <Container>
        <div className={style.main}>
          <h1>Motivações Solicitadas</h1>
          <div className={style.grid}>
            <div className={style.row}>
              {Array.isArray(motivacoes) && motivacoes.length > 0 ? (
                motivacoes.map((motivacao, index) => (
                  <div
                    key={index}
                    className={`${style.grid_2} ${style.requirement} ${selectedMotivacaoId === motivacao.ID_MOTIVACAO ? style.active : ''}`}
                    onClick={() => handleMotivacaoClick(motivacao)}
                  >
                    <div>
                      <h2>{motivacao.NOME_REDUZIDO}</h2>
                      <div className={style.requirement_desc}>
                        Berços: {motivacao.DESC_BERCO_SPA}
                      </div>
                    </div>
                    <div className={style.button_container}>
                      <button className={`${style.button} ${style.check}`} disabled={disabledButtons[motivacao.ID_MOTIVACAO]} onClick={() => handleMotivacaoAction(motivacao.ID_MOTIVACAO, 'Aprovado')}>
                        <FaCheck />
                      </button>
                      <button className={`${style.button} ${style.block}`} disabled={disabledButtons[motivacao.ID_MOTIVACAO]} onClick={() => handleMotivacaoAction(motivacao.ID_MOTIVACAO, 'Recusado')}>
                        <FaBan />
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <p>Nenhuma motivação encontrada.</p>
              )}
            </div>

            <div className={style.requirement_full}>
                {empresa ? (
                    <>
                    <div className={style.requirement_header}>
                        <h1>{empresa}</h1>
                        <div className={style.header_date}>
                        <FaCalendar />
                        <h1>{periodo}</h1> {/* Período da motivação */}
                        </div>
                        <div className={style.header_button_container}>
                        <button className={`${style.button2} ${style.check}`} disabled={disabledButtons[idMotivacao]} onClick={() => handleMotivacaoAction(idMotivacao, 'Aprovado')}>
                            <FaCheck />
                        </button>
                        <button className={`${style.button2} ${style.block}`} disabled={disabledButtons[idMotivacao]} onClick={() => handleMotivacaoAction(idMotivacao, 'Recusado')}>
                            <FaBan />
                        </button>
                        </div>
                    </div>

                    <div className={style.requirement_motivo}>
                        <strong>Motivo:</strong>
                        <div className={style.box}>
                            {motivo}
                        </div>
                        <strong>Berços:</strong>
                        <div className={style.box}>
                            {bercos}
                        </div>
                    </div>
                    <div className={style.motivados_div}>
                      <strong>Motivados:</strong>
                      <div className={style.requirement_motivados}>
                          {motivados.length > 0 ? (
                          motivados.map((motivado, index) => (
                              <div key={index} className={style.motivado}>
                              {motivado.nome} ({motivado.cpf})
                              </div>
                          ))
                          ) : (
                          <p>Nenhum motivado encontrado.</p>
                          )}
                      </div>
                    </div>

                    <div className={style.requirement_motivados}>
                        {motivados.length > 0 ? (
                          <>
                            {nomeacaoButton ? 
                              <button className={style.nomeacao_button} onClick={handleModal}>Nomeação</button> 
                              :
                              null
                            }
                            
                            <Modal isOpen={modal} onBack={handleModal} onClose={handleModal}>
                              <div className={style.nomeacao_div}>
                                <img src={nomeacao} alt="nomeação" className={style.nomeacao}/>
                              </div>
                            </Modal>
                          </>
                        ) : (
                        null
                        )}
                    </div>
                    </>
                ) : (
                  <div className={style.CalliBot_Center}>
                    <img className={style.callibot} src={CalliBot} alt='Imagem' />
                  </div>
                )}
                </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Aprovacao;

import React from 'react';
import { createRoot } from 'react-dom/client';
import { SnackbarProvider } from 'notistack';
import App from './App';

const container = document.getElementById('root');

const root = createRoot(container);

root.render(
    <SnackbarProvider 
    maxSnack={3}
    anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
    }}>
        <App />
    </SnackbarProvider>
);

import React, { useState } from "react";
import style from './virtual-tour-style.module.css';
import Container from '../../components/Container/index.js';
import MapImg from "../../img/sts-map.svg";
import AlamoaMapImg from "../../img/alamoa-map.svg";
import SabooMapImg from "../../img/saboo-map.svg";
import ValongoMapImg from "../../img/valongo-map.svg";
import QuintaMapImg from "../../img/5-seccao-map.svg";
import PaquetaMapImg from "../../img/paqueta-map.svg";
import OuteirinhosMapImg from "../../img/outeirinhos-map.svg";
import { useSnackbar } from "notistack";

const regions = [
  { name: "Alamoa", img: AlamoaMapImg, url: "" },
  { name: "Saboo", img: SabooMapImg, url: "https://portal.axmlogistica.com.br/saboo/" },
  { name: "Valongo", img: ValongoMapImg, url: "" },
  { name: "Paqueta", img: PaquetaMapImg, url: "https://portal.axmlogistica.com.br/paqueta/" },
  { name: "Outeirinhos", img: OuteirinhosMapImg, url: "" },
  { name: "Quinta", img: QuintaMapImg, url: "https://portal.axmlogistica.com.br/quinta/" }
];

const VirtualTour = () => {
  const [iframeUrl, setIframeUrl] = useState("");
  const [selectedRegion, setSelectedRegion] = useState(null);
  const { enqueueSnackbar } = useSnackbar();


  const handleMapClick = (url, regionName) => {
    if (!url) {
      return enqueueSnackbar('Região ainda não catalogada!', { variant: 'error'})
    }
    if (selectedRegion === regionName) {
      setIframeUrl(null);
      setSelectedRegion(null); 
    } else {
      setIframeUrl(url);
      setSelectedRegion(regionName); 
    }
  };

  return (
    <div>
      <Container>
        <div className={style.main}>
        <div className={style.main_content}>
            {iframeUrl ? (
              <div className={style.iframe_container}>
                <iframe
                  src={iframeUrl}
                  title="Marzipano Viewer"
                  width="100%"
                  height="100vh"
                  style={{ border: "none", borderRadius: "10px", height: "77vh" }}
                  allowFullScreen
                ></iframe>
              </div>
            ) : (
              <div className={style.mapBackground}>
                {regions.map((region) => (
                  <img
                    key={region.name}
                    src={region.img}
                    className={`${style.map_item} ${style[`${region.name.toLowerCase()}_map`]}`}
                    alt="map-img"
                    onClick={() => handleMapClick(region.url, region.name)}
                  />
                ))}
                <img src={MapImg} className={style.mapBase} alt="map-img" />
              </div>
            )}
          </div>
          <div className={style.region_list}>
            <ul>
              {regions.map((region) => (
                <li
                  key={region.name}
                  className={selectedRegion === region.name ? style.selectedRegion : ""}
                  onClick={() => handleMapClick(region.url, region.name)}
                >
                  {region.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default VirtualTour;

import api from './api.js';

const listarEmpresas = async () => {
    try {
        const response = await api.get('/api/empresas/listar');
        return response.data;
    } catch (error) {
        throw error;
    }
};

const inserirEmpresa = async (razaoSocial, nomeReduzido, CNPJ) => {
    try {
        const response = await api.post('/api/empresas/inserir', {
            razaosocial: razaoSocial,
            nomecurto: nomeReduzido,
            cnpj: CNPJ
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

const alterarEmpresa = async (id, razaoSocial, nomeReduzido, CNPJ) => {
    try {
        const response = await api.put(`/api/empresas/alterar/`, {
            razaosocial: razaoSocial,
            nomecurto: nomeReduzido,
            cnpj: CNPJ,
            codigoempresa: id
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export { listarEmpresas, inserirEmpresa, alterarEmpresa };

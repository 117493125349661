import React from "react";
import Container from "../../components/Container";
import style from "./Dash.module.css";



const Dashboard = () => {
  return (
    <>
      <Container>
      <div className={style.centerLogo}>

</div>
 <br />
      </Container>
    </>
  );
};

export default Dashboard;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Background_feixe1__IYhjY {\n    background-color: #F4F6FA;\n    position: fixed; /* Mantém o fundo fixo mesmo com o scroll */\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n    z-index: -1; /* Coloca o fundo atrás de todo o conteúdo */\n}\n", "",{"version":3,"sources":["webpack://./src/components/Background/Background.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,eAAe,EAAE,2CAA2C;IAC5D,WAAW;IACX,YAAY;IACZ,MAAM;IACN,OAAO;IACP,WAAW,EAAE,4CAA4C;AAC7D","sourcesContent":[".feixe1 {\n    background-color: #F4F6FA;\n    position: fixed; /* Mantém o fundo fixo mesmo com o scroll */\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n    z-index: -1; /* Coloca o fundo atrás de todo o conteúdo */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"feixe1": "Background_feixe1__IYhjY"
};
export default ___CSS_LOADER_EXPORT___;

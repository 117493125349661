const IBGE_API_BASE_URL = 'https://servicodados.ibge.gov.br/api/v1/localidades';

async function buscarEstados() {
    try {
    const response = await fetch(`${IBGE_API_BASE_URL}/estados`);
    if (!response.ok) {
        throw new Error('Erro ao buscar estados');
    }
    const estados = await response.json();
    return estados;
    } catch (error) {
    console.error('Erro:', error);
    }
}

async function buscarCidades(UF) {
    try {
    const response = await fetch(`${IBGE_API_BASE_URL}/estados/${UF}/municipios`);
    if (!response.ok) {
        throw new Error('Erro ao buscar cidades');
    }
    const cities = await response.json();
    return cities;
    } catch (error) {
    console.error('Erro:', error);
    }
}

export { buscarCidades, buscarEstados };

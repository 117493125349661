import axios from 'axios';

const apiURL = '/api/eventual/criar';

async function criarEventual(params) {
    try {
        console.log(params);
        // Envia os parâmetros diretamente no corpo da solicitação POST
        const response = await axios.post(apiURL, {
            params: params
        });

        if (response.status !== 200) {
            throw new Error('Erro ao criar eventual');
        }

        return true;
    } catch (error) {
        console.error('Erro:', error);
        return false;
    }
}
export { criarEventual };

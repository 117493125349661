import './eventual.css';

import search from "../../img/search.svg";
import { useEffect, useState } from 'react';
import { buscarEstados, buscarCidades } from '../../services/api-ibge.js';
import LocationInput from '../../components/LocationInput/location-input.jsx';
import Container from '../../components/Container/index.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { criarEventual } from '../../services/api-sspp.js';
import style from './CustomDatePicker.css';
import { useSnackbar } from 'notistack';

const Eventual = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [states, setStates] = useState([]);
    const [selectedState, setSelectedState] = useState("");
    const [cpf, setCpf] = useState("");
    const [dataExpedicao, setDataExpedicao] = useState(null);
    const [dataNascimento, setNascimento] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState([]);
    const [formData, setFormData] = useState({
        nome: '',
        cpf: '',
        nomePai: '',
        nomeMae: '',
        tipoDoc: '',
        numDoc: '',
        diaExpedicao: '',
        mesExpedicao: '',
        anoExpedicao: '',
        diaNascimento: '',
        mesNascimento: '',
        anoNascimento: '',
        orgaoEmissor: '',
        ufOrgaoEmissor: '',
        localNascimento: '',
        ufNascimento: '',
        ctpsNumero: '',
        ctpsSerie: '',
        cep: '',
        bairro: '',
        complemento: '',
        cidade: '',
        endereco: '',
        ufEndereco: '',
        telefone: '',
        profissao: '',
        empresa: '',
        localDestino: '',
        motivacao: '',
        periodo: '',
        gates: '',
        gates2: '',
        numero: '',
        diaInicio:'',
        mesInicio:'',
        anoInicio:'',
        diaFim:'',
        mesFim:'',
        anoFim:'',
    });

    const handleCityChange = (selectedCity) => {
        setSelectedCity(selectedCity);
        setFormData({ ...formData, cidade: selectedCity.nome });
    }

    const showAlert = (message, variant) => {
        enqueueSnackbar(message, { variant });
    };
    const cpfMask = (value) => {
        return value
            .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
            .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada    
    };

    const handleExpDate = (date) => {
        setDataExpedicao(date);
        const selectedDay = date.getDate();
        const selectedMonth = date.getMonth() + 1;
        const selectedYear = date.getFullYear();
        setFormData({ ...formData, 
            diaExpedicao: selectedDay,
            mesExpedicao: selectedMonth,
            anoExpedicao: selectedYear
        });
    };

    const handleNascDate = (date) => {
        setNascimento(date);
        const selectedDay = date.getDate();
        const selectedMonth = date.getMonth() + 1;
        const selectedYear = date.getFullYear();
        setFormData({ ...formData, 
            diaNascimento: selectedDay,
            mesNascimento: selectedMonth,
            anoNascimento: selectedYear
        });
    };

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        if (start && end) {
            // Calcular a diferença entre as datas
            const diffInDays = (end - start) / (1000 * 60 * 60 * 24);
            if (diffInDays > 10) {
                showAlert('O período deve ser de no máximo 10 dias.', 'error');
                return; // Não atualiza as datas se o intervalo for maior que 10 dias
            }
        }
        setStartDate(start);
        setEndDate(end);
        setFormData({ ...formData,
            diaInicio: start.getDate(),
            mesInicio: start.getMonth() + 1,
            anoInicio: start.getFullYear(),
            diaFim: end.getDate(),
            mesFim: end.getMonth() + 1,
            anoFim: end.getFullYear(),
        });
        if (start && end) {
            setShowDatePicker(false);
        }
    };

    useEffect(() => {
        const loadStates = async () => {
            const statesData = await buscarEstados();
            setStates(statesData);
        };

        loadStates();
    }, []);

    const handleStateChange = async (event) => {
        const stateId = event.target.value;
        setSelectedState(stateId);
        setFormData({ ...formData, ufEndereco: stateId });
        setSelectedCity('')
        setCities([])
        if (stateId) {
            const citiesData = await buscarCidades(stateId);
            setCities(citiesData);
        }
    };
    const handleStateChange1 = async (event) => {
        const stateId = event.target.value;
        setSelectedState(stateId);
        setFormData({ ...formData, ufOrgaoEmissor: stateId });
        setSelectedCity('')
        setCities([])
        if (stateId) {
            const citiesData = await buscarCidades(stateId);
            setCities(citiesData);
        }
    };

    const handleCPF = (event) => {
        const cpfValue = cpfMask(event.target.value);
        setCpf(cpfValue);
        setFormData((prevFormData) => ({
            ...prevFormData,
            cpf: cpfValue,
            ctpsNumero: cpfValue.replace(/\D/g, '').slice(0, 7),
            ctpsSerie: cpfValue.replace(/\D/g, '').slice(-4)
        }));
    };


    const buscarEnderecoPorCEP = async (cep) => {
        try {
            const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
            const data = await response.json();
            if (data.erro) {
                throw new Error('CEP não encontrado');
            }
            return data;
        } catch (error) {
            console.error('Erro ao buscar o endereço:', error);
            return null;
        }
    };

    const cepMask = (value) => {
        return value
            .replace(/\D/g, '') // Remove caracteres não numéricos
            .replace(/(\d{5})(\d)/, '$1-$2'); // Adiciona hífen após os 5 primeiros dígitos
    };


    const handleInputChange = async (event) => {
        const { name, value } = event.target;
        let newValue = value;
    
        if (name === 'cep') {
            newValue = cepMask(value);
            event.target.value = newValue;
    
            const cepValue = newValue.replace('-', '');
            if (cepValue.length === 8) { // Verifica se o CEP tem 8 dígitos
                try {
                    const endereco = await buscarEnderecoPorCEP(cepValue);
                    if (endereco) {
                        const { logradouro, complemento, localidade, uf, bairro } = endereco;
                        const citiesData = await buscarCidades(uf);

                        setSelectedState(uf);
                        setCities(citiesData);
                        setSelectedCity(localidade);                      
                        setFormData((prevState) => ({
                            ...prevState,
                            cep: cepValue,
                            endereco: logradouro,
                            complemento: complemento || '',
                            cidade: localidade,
                            ufEndereco: uf,
                            bairro: bairro,
                        }));
                    }
                } catch (error) {
                    console.error('Erro ao buscar o endereço:', error);
                }
            } else {
                setFormData((prevState) => ({
                    ...prevState,
                    [name]: cepValue
                }));
            }
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: newValue
            }));
        }
    };
    



    const handleLocationStateChange = (stateId) => {
        console.log(stateId)
        setFormData({ ...formData, ufNascimento: stateId });
    };

    const handleLocationCityChange = (cityId) => {
        console.log(cityId)
        setFormData({ ...formData, localNascimento: cityId });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true); // Define o estado de carregamento como verdadeiro

        try {
            await criarEventual(formData); // Envia os dados para a API
            // Sucesso: você pode adicionar lógica para notificar o usuário ou redirecionar
        } catch (error) {
            console.error('Erro ao enviar dados:', error);
            // Erro: você pode adicionar lógica para notificar o usuário sobre o erro
        } finally {
            setLoading(false); // Define o estado de carregamento como falso
        }
    };

    return (
        <>
            <Container>
                <div className='eventual'>
                    <div className='container'>
                        <form onSubmit={handleSubmit}>
                            <div className='row mt-5 eventual-custom-border'>
                                <h1 className='p-5'>Buscar Colaborador</h1>
                                <div className='row ml-5 mr-5 eventual-buscar'>
                                    <input placeholder="Buscar pelo nome, CPF ou RG" className="col-md-2" type="text" />
                                    <button className="col-md-1 eventual-search">
                                        <img src={search} alt="" />
                                    </button>
                                </div>
                            </div>
                            <div className='mt-5 eventual-custom-border p-5 grid'>
                                <div>
                                    <h1 className='eventual-form'>
                                        Nome
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <input placeholder="Digite o nome completo" name="nome" type="text" required onChange={handleInputChange} />
                                </div>
                                <div>
                                    <h1 className='eventual-form'>
                                        CPF
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <input placeholder="Número do CPF" name="cpf" value={cpf} onChange={handleCPF} type="text" required />
                                </div>
                                <div>
                                    <h1 className='eventual-form'>
                                        Nome do pai
                                    </h1>
                                    <input placeholder="Digite o nome completo" name="nomePai" type="text" onChange={handleInputChange} />
                                </div>
                                <div>
                                    <h1 className='eventual-form'>
                                        Nome da mãe
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <input placeholder="Digite o nome completo" name="nomeMae" type="text" required onChange={handleInputChange} />
                                </div>
                                <div>
                                    <h1 className='eventual-form'>
                                        Tipo de Documento
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <select id="tipo-doc" name="tipoDoc" required onChange={handleInputChange}>
                                        <option value="">Selecione uma opção</option>
                                        <option value="1">RG</option>
                                        <option value="6">Matrícula</option>
                                        <option value="2">Passaporte</option>
                                        <option value="9">Placa</option>
                                        <option value="3">RNE</option>
                                        <option value="4">SIPE</option>
                                    </select>
                                </div>
                                <div>
                                    <h1 className='eventual-form'>
                                        Número do Documento
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <input placeholder="Digite o número do documento" name="numDoc" type="text" required onChange={handleInputChange} />
                                </div>
                                <div className=''>
                                    <h1 className='eventual-form'>
                                        Data de Expedição
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <DatePicker
                                        required
                                        selected={dataExpedicao}
                                        onChange={handleExpDate}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="Selecione a data"
                                        className="eventual-date"
                                    />
                                </div>
                                <div>
                                <h1 className='eventual-form'>
                                        Data de Nascimento
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <div>
                                        <DatePicker
                                            required
                                            selected={dataNascimento}
                                            onChange={handleNascDate}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="Selecione a data"
                                            className="eventual-date"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <h1 className='eventual-form'>
                                        Órgão Emissor
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <div className='eventual-local'>
                                        <select id="state" name="ufOrgaoEmissor" onChange={handleStateChange1} value={formData.ufOrgaoEmissor} required>
                                            <option value="">UF</option>
                                            <option value="AC">AC</option>
                                            <option value="AL">AL</option>
                                            <option value="AM">AM</option>
                                            <option value="AP">AP</option>
                                            <option value="BA">BA</option>
                                            <option value="CE">CE</option>
                                            <option value="DF">DF</option>
                                            <option value="ES">ES</option>
                                            <option value="GO">GO</option>
                                            <option value="MA">MA</option>
                                            <option value="MG">MG</option>
                                            <option value="MS">MS</option>
                                            <option value="MT">MT</option>
                                            <option value="NF">NF</option>
                                            <option value="PA">PA</option>
                                            <option value="PB">PB</option>
                                            <option value="PE">PE</option>
                                            <option value="PI">PI</option>
                                            <option value="PR">PR</option>
                                            <option value="RJ">RJ</option>
                                            <option value="RN">RN</option>
                                            <option value="RO">RO</option>
                                            <option value="RR">RR</option>
                                            <option value="RS">RS</option>
                                            <option value="SC">SC</option>
                                            <option value="SE">SE</option>
                                            <option value="SP" >SP</option>
                                            <option value="TO">TO</option>
                                        </select>
                                        <input placeholder="Órgão" type="text" name="orgaoEmissor" onChange={handleInputChange} />
                                    </div>
                                </div>
                                <div>
                                    <h1 className='eventual-form'>
                                        Local de Nascimento
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <div className='eventual-local'>
                                        <LocationInput
                                            onStateChange={handleLocationStateChange}
                                            onCityChange={handleLocationCityChange}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <h1 className='eventual-form'>
                                        CTPS
                                    </h1>
                                    <div className='col-span-2 eventual-double-field'>
                                        <input className='col-span-2' placeholder="Número" type="text" name="ctpsNumero" onChange={handleInputChange} value={formData.ctpsNumero} />
                                        <input placeholder="Série" type="text" name="ctpsSerie" onChange={handleInputChange} value={formData.ctpsSerie} />
                                    </div>
                                </div>
                                <div>
                                    <h1 className='eventual-form'>
                                        CEP
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <input type="text" name="cep" onChange={handleInputChange} placeholder='00000-000' required />
                                </div>
                                <div className='grid'>
                                    <div>
                                    <h1 className='eventual-form'>
                                            Complemento
                                    </h1>
                                    <input type="text" name="complemento" onChange={handleInputChange} value={formData.complemento} placeholder='Complemento' />
                                    </div>
                                    <div className=''>
                                        <h1 className='eventual-form'>
                                            Bairro
                                            <span className='eventual-required'>*</span>
                                        </h1>
                                        <input type="text" name="bairro" onChange={handleInputChange} value={formData.bairro} placeholder='Bairro' required/>
                                    </div>
                                </div>
                                <div>
                                    <h1 className='eventual-form'>
                                            Cidade
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <div className='eventual-local'>
                                        <select id="state" name="ufEndereco" onChange={handleStateChange} value={formData.ufEndereco} required>
                                            <option value="">UF</option>
                                            <option value="AC">AC</option>
                                            <option value="AL">AL</option>
                                            <option value="AM">AM</option>
                                            <option value="AP">AP</option>
                                            <option value="BA">BA</option>
                                            <option value="CE">CE</option>
                                            <option value="DF">DF</option>
                                            <option value="ES">ES</option>
                                            <option value="GO">GO</option>
                                            <option value="MA">MA</option>
                                            <option value="MG">MG</option>
                                            <option value="MS">MS</option>
                                            <option value="MT">MT</option>
                                            <option value="NF">NF</option>  
                                            <option value="PA">PA</option>
                                            <option value="PB">PB</option>
                                            <option value="PE">PE</option>
                                            <option value="PI">PI</option>
                                            <option value="PR">PR</option>
                                            <option value="RJ">RJ</option>
                                            <option value="RN">RN</option>
                                            <option value="RO">RO</option>
                                            <option value="RR">RR</option>
                                            <option value="RS">RS</option>
                                            <option value="SC">SC</option>
                                            <option value="SE">SE</option>
                                            <option value="SP" >SP</option>
                                            <option value="TO">TO</option>
                                        </select>
                                        <select id="city" name="cidade" onChange={handleCityChange} value={formData.cidade} required>
                                            <option value="">Selecione uma cidade</option>
                                            {cities.map((city) => (
                                                <option key={city.id} value={city.nome}>{city.nome}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div>
                                    <h1 className='eventual-form'>
                                        Endereço
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <div className='eventual-endereco'>
                                    <input type="text" name="endereco" onChange={handleInputChange} value={formData.endereco} placeholder='Logradouro'required />
                                    <input type="text" name="numero" onChange={handleInputChange} value={formData.numero} placeholder='N°'required />

                                    </div>
                                </div>
                                <div>
                                    <h1 className='eventual-form'>
                                        Telefone
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <input type="text" name="telefone" onChange={handleInputChange} placeholder='Número de telefone ou celular' required />
                                </div>
                                <div>
                                    <h1 className='eventual-form'>
                                        Profissão
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <input type="text" name="profissao" onChange={handleInputChange} placeholder='Emprego' required />
                                </div>
                                <div>
                                    <h1 className='eventual-form'>
                                        Empresa
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <input type="text" name="empresa" onChange={handleInputChange} placeholder='Empresa onde trabalha'required />
                                </div>
                                <div>
                                    <h1 className='eventual-form'>
                                        Local de Destino
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <input type="text" name="localDestino" onChange={handleInputChange} placeholder='Local onde o colaborador irá' required />
                                </div>
                                <div className=''>
                                    <h1 className='eventual-form'>
                                        Período
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <div className='eventual-flex'>
                                        <div className='eventual-teste'>
                                            <div className={`${style.dateRangeLabel} ${style.centeredLabel}`} onClick={() => setShowDatePicker(true)}>
                                                {startDate && endDate
                                                    ? `${startDate.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' })} - ${endDate.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' })}`
                                                    : 'Selecione o período'}
                                            </div>
                                            {showDatePicker && (
                                                <div className={style.datePickerWrapper}>
                                                    <DatePicker
                                                        selected={startDate}
                                                        onChange={handleDateChange}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        selectsRange
                                                        inline
                                                        monthsShown={1}
                                                        locale="pt-BR"
                                                        dateFormat="dd/MM/yy"
                                                        placeholderText="Selecione o período"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-span-2'>
                                    <h1 className='eventual-form'>
                                        Motivação
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <textarea className='eventual-textarea' name="motivacao" onChange={handleInputChange} required></textarea>
                                </div>
                                <div className='col-span-2'>
                                    <h1 className='eventual-form'>
                                        Gates
                                    </h1>
                                    <div className='eventual-gate'>
                                        <select id="gates" name="gates" onChange={handleInputChange} required>
                                            <option value="">Selecione uma opção</option>
                                            <option value="1">Alamoa: Gate 3</option>
                                            <option value="2">Saboó: Gate 4 - Gate 5</option>
                                            <option value="3">Paquetá: Gate 6 - Gate 7 - Gate 8</option>
                                            <option value="4">Outeirinho: Gate 9 - Gate 10 - Gate 11 - Gate 12</option>
                                            <option value="5">Macuco: Gate 14 - Gate 15 - Gate 17 - Gate 18</option>
                                            <option value="6">Travessia para Margem Esquerda: Gate 19</option>
                                            <option value="7">Ponta da Praia: Gate 21</option>
                                            <option value="8">Ilha Barnabé: Gate 27</option>
                                            <option value="9">PFP Ilha Barnabé 04: Gate 28</option>
                                            <option value="12">Margem Esquerda - TEV: Gate TEV</option>
                                            <option value="13">Canal/Barra: Gate MAR</option>
                                        </select>
                                        <select id="gates2" name="gates2" onChange={handleInputChange}>
                                            <option value="">Selecione uma opção</option>
                                            <option value="1">Alamoa: Gate 3</option>
                                            <option value="2">Saboó: Gate 4 - Gate 5</option>
                                            <option value="3">Paquetá: Gate 6 - Gate 7 - Gate 8</option>
                                            <option value="4">Outeirinho: Gate 9 - Gate 10 - Gate 11 - Gate 12</option>
                                            <option value="5">Macuco: Gate 14 - Gate 15 - Gate 17 - Gate 18</option>
                                            <option value="6">Travessia para Margem Esquerda: Gate 19</option>
                                            <option value="7">Ponta da Praia: Gate 21</option>
                                            <option value="8">Ilha Barnabé: Gate 27</option>
                                            <option value="9">PFP Ilha Barnabé 04: Gate 28</option>
                                            <option value="12">Margem Esquerda - TEV: Gate TEV</option>
                                            <option value="13">Canal/Barra: Gate MAR</option>
                                        </select>
                                    </div>
                                </div>
                                
                                <button type="submit" className="col-span-2 eventual-submit" disabled={loading}>
                                    {loading && <div className="spinner"></div>}
                                    {loading ? 'Enviando...' : 'Enviar'}
                                    <span className="button-text">
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Container>
        </>
    );
}

export default Eventual;

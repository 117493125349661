import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import InputMask from 'react-input-mask';
import Container from '../../components/Container/index.js';
import DataTable from '../../components/Data-table/data-table.jsx';
import pencil from '../../img/Pencil.svg';
import search from "../../img/search.svg";
import { useSnackbar } from 'notistack';
import { listarEmpresas, alterarEmpresa, inserirEmpresa } from '../../services/api-empresas.js';
import './admin.css';

const Admin = () => {
    const { enqueueSnackbar } = useSnackbar();

    const [filteredData, setFilteredData] = useState([]);
    const [modalVisibility, setModalVisibility] = useState(false);
    const [empresas, setEmpresas] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [incluir, setIncluir] = useState(false);
    const [dataEmpresa, setDataEmpresa] = useState({
        razaosocial: '',
        nomecurto: '',
        cnpj: '',
        codigoempresa: '',
        inserir: false
    });

    const paginationComponentOptions = {
        rowsPerPageText: 'Linhas por página:',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const columns = [
        {
            name: 'Razão Social',
            selector: row => row.RAZAO_SOCIAL,
            sortable: true,
        },
        {
            name: 'CNPJ',
            selector: row => formatCNPJ(row.CNPJ),
            sortable: true,
        },
        {
            name: 'Ações',
            cell: row => <button onClick={() => handleEdit(row)}><img src={pencil} alt="Editar" /></button>,
            maxWidth: '50px',
        }
    ];

    useEffect(() => {
        const fetchEmpresas = async () => {
            try {
                const data = await listarEmpresas();
                setEmpresas(data);
                setFilteredData(data);
            } catch (error) {
                console.error('Erro ao buscar empresas:', error);
            }
        };

        fetchEmpresas();
    }, []);
    useEffect(() => {
        const fetchEmpresas = async () => {
            try {
                const data = await listarEmpresas();
                setEmpresas(data);
                setFilteredData(data);
            } catch (error) {
                console.error('Erro ao buscar empresas:', error);
            }
        };

        fetchEmpresas();
    }, [modalVisibility]);

    useEffect(() => {
        const filtered = empresas.filter(empresa => 
            empresa.RAZAO_SOCIAL.toLowerCase().includes(searchTerm.toLowerCase()) || 
            formatCNPJ(empresa.CNPJ).includes(searchTerm)
        );
        setFilteredData(filtered);
    }, [searchTerm, empresas]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleEdit = (row) => {
        setDataEmpresa({
            razaosocial: row.RAZAO_SOCIAL,
            nomecurto: row.NOME_REDUZIDO,
            cnpj: row.CNPJ,
            codigoempresa: row.COD_EMPRESA
        });
        setIncluir(false);
        setModalVisibility(!modalVisibility);
    };

    const handleIncluir = () => {
        setDataEmpresa({
            razaosocial: '',
            nomecurto: '',
            cnpj: '',
            codigoempresa: ''
        });
        setModalVisibility(!modalVisibility);
        setIncluir(true);
    };

    const handleCancelar = () => {
        setModalVisibility(!modalVisibility);
    };

    const handleSalvar = async (event) => {
        event.preventDefault();
        let cnpjSemMascara
        try {
            cnpjSemMascara = dataEmpresa.cnpj.replace(/\D/g, ''); // Remove a máscara
            
        } catch (error) {
            cnpjSemMascara = dataEmpresa.cnpj
        }

        if (incluir) {
            try {
                await inserirEmpresa(dataEmpresa.razaosocial, dataEmpresa.nomecurto, cnpjSemMascara);
                setModalVisibility(!modalVisibility);
                enqueueSnackbar('Cadastro inserido com sucesso!', { variant: 'success' });
            } catch (error) {
                enqueueSnackbar('Erro ao inserir cadastro!', { variant: 'error' });
            } 
        } else {
            try {
                await alterarEmpresa(dataEmpresa.codigoempresa, dataEmpresa.razaosocial, dataEmpresa.nomecurto, cnpjSemMascara);
                setModalVisibility(!modalVisibility);
                enqueueSnackbar('Cadastro Atualizado com sucesso!', { variant: 'success' });
            } catch (error) {
                enqueueSnackbar('Erro ao atualizar cadastro!', { variant: 'error' });
            }
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDataEmpresa((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const formatCNPJ = (cnpj) => {
        let cnpjString = cnpj.toString();
        return cnpjString.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
    };
    

    return (
        <>
            <Container>
                <div className='admin-container'>
                    <div className='nav mb-5'>
                        <h1 className='navItem'>
                            <i className="fa fa-gear"></i> Painel Administrativo
                        </h1>
                    </div>
                    <hr />

                    <div className='row mt-5 '>
                        <div className='grid admin-grid'>
                            <h1 className='p-5'>Empresas Cadastradas</h1>
                            <div className='row ml-5 mr-5 admin-buscar'>
                                <input 
                                    placeholder="Buscar pela razão social ou CNPJ" 
                                    className="col-md-2" 
                                    type="text" 
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                />
                                <button className="col-md-1 admin-search">
                                    <img src={search} className='admin-lupa' alt="Buscar" />
                                </button>
                                <button className="col-md-1 admin-incluir-empresa" onClick={handleIncluir}>
                                    Incluir Nova Empresa
                                </button>
                            </div>
                        </div>
                    </div>

                    <Modal
                        isOpen={modalVisibility}
                        className='admin-modal'
                    >
                        <form>
                            <div>
                                <h1>Razão Social</h1>
                                <input type="text" name="razaosocial" value={dataEmpresa.razaosocial} required onChange={handleInputChange} />
                            </div>
                            <div>
                                <h1>Nome Curto</h1>
                                <input type="text" name="nomecurto" value={dataEmpresa.nomecurto} required onChange={handleInputChange} />
                            </div>
                            <div>
                                <h1>CNPJ</h1>
                                <InputMask
                                    mask="99.999.999/9999-99"
                                    value={dataEmpresa.cnpj}
                                    onChange={handleInputChange}
                                >
                                    {(inputProps) => <input type="text" name="cnpj" required {...inputProps} />}
                                </InputMask>
                            </div>
                            <div className='admin-modal-buttons'>
                                <button className="admin-salvar" onClick={handleSalvar}>Salvar</button>
                                <button className="admin-cancelar" onClick={handleCancelar}>Cancelar</button>
                            </div>
                        </form>
                    </Modal>
                    <DataTable
                        className='p-3'
                        columns={columns}
                        data={filteredData}
                        pagination={true}
                        noDataComponent="Sem Dados"
                        paginationComponentOptions={paginationComponentOptions}
                        highlightOnHover={true}
                    />
                </div>
            </Container>
        </>
    );
}

export default Admin

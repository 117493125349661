import React from 'react';
import DataTable from 'react-data-table-component';


const selectProps = { indeterminate: isIndeterminate => isIndeterminate };

function DataTableBase(props) {
	return (
		<DataTable
			selectableRowsComponentProps={selectProps}
			{...props}
		/>
	);
}

export default DataTableBase;
import { createContext, useEffect, useState } from "react";
import axios from 'axios';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const userData = JSON.parse(atob(token.split('.')[1])); // Decodifica o payload do JWT
            setUser(userData);
        }
    }, []);

    const login = async (id, password) => {
        try {
            const response = await axios.post('/api/login', { id, senha: password });

            if (response.data.token) {
                const token = response.data.token;
                localStorage.setItem("token", token);

                const userData = JSON.parse(atob(token.split('.')[1])); // Decodifica o payload do JWT
                setUser(userData);

                return;
            } else {
                return response.data.error;
            }
        } catch (err) {
            return 'Login ou Senha Inválida';
        }
    };

    const logout = () => {
        setUser(null);
        localStorage.removeItem("token");
    };

    return (
        <AuthContext.Provider value={{ user, signed: !!user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImgUpload_imgUploadContainer__rd0jE {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 20px;\n    border-radius: 10px;\n    background-color: #f8f9fa;\n  }\n  \n  .ImgUpload_ImageUploadDropZone__4OzKE {\n    border: 2px solid #2D99AA;\n    border-radius: 10px;\n    width: 100%;\n    padding: 20px;\n    text-align: center;\n    cursor: pointer;\n    transition: all 0.6s;\n  }\n  .ImgUpload_previewImage__1EU4H {\n    margin-top: 10px;\n    max-width: 100%; /* Ajusta a largura para não sair do contêiner */\n    height: auto; /* Mantém a proporção da imagem */\n  }\n  .ImgUpload_dragging__N3wxa {\n    background-color: rgb(0, 153, 255);\n    color: white;\n    font-weight: bold;\n  }", "",{"version":3,"sources":["webpack://./src/components/ImgUpload/ImgUpload.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;IACzB,mBAAmB;IACnB,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,eAAe;IACf,oBAAoB;EACtB;EACA;IACE,gBAAgB;IAChB,eAAe,EAAE,gDAAgD;IACjE,YAAY,EAAE,iCAAiC;EACjD;EACA;IACE,kCAAkC;IAClC,YAAY;IACZ,iBAAiB;EACnB","sourcesContent":[".imgUploadContainer {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 20px;\n    border-radius: 10px;\n    background-color: #f8f9fa;\n  }\n  \n  .ImageUploadDropZone {\n    border: 2px solid #2D99AA;\n    border-radius: 10px;\n    width: 100%;\n    padding: 20px;\n    text-align: center;\n    cursor: pointer;\n    transition: all 0.6s;\n  }\n  .previewImage {\n    margin-top: 10px;\n    max-width: 100%; /* Ajusta a largura para não sair do contêiner */\n    height: auto; /* Mantém a proporção da imagem */\n  }\n  .dragging {\n    background-color: rgb(0, 153, 255);\n    color: white;\n    font-weight: bold;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imgUploadContainer": "ImgUpload_imgUploadContainer__rd0jE",
	"ImageUploadDropZone": "ImgUpload_ImageUploadDropZone__4OzKE",
	"previewImage": "ImgUpload_previewImage__1EU4H",
	"dragging": "ImgUpload_dragging__N3wxa"
};
export default ___CSS_LOADER_EXPORT___;

import { useState, useEffect } from 'react';
import axios from 'axios';

const useAuth = () => {
    const [signed, setSigned] = useState(null); // Inicialmente null para indicar carregamento

    useEffect(() => {
        const fetchData = async() => {
            try {
                const token = localStorage.getItem('token');
                console.log('/api/login/verify/token')
                const valid = await axios.get('/api/login/verify/token', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                if (valid.status === 200) {
                    setSigned(true);
                }
            } catch {
                setSigned(false)
            }
        }
        
        fetchData()
    }, []);

    return { signed };
};

export default useAuth;
